import {createRouter, createWebHistory} from '@ionic/vue-router';
import {RouteRecordRaw} from 'vue-router';
import {TokenService} from "@/services/token.service";
import AuthLayout from "@/layouts/AuthLayout.vue";
import AdminLayout from "@/layouts/AdminLayout.vue";

const routes: Array<RouteRecordRaw> = [
    {
        path: '',
        redirect: '/admin/dashboard'
    },
    {
        path: '/auth/',
        component: AuthLayout,
        meta: {

            public: true,
            onlyWhenLoggedOut: true
        },
        children: [
            {
                path: '',
                redirect: '/auth/sign-in'
            },
            {
                path: 'sign-in',
                component: () => import('@/views/auth/SignIn.vue')
            },
            {
                path: 'sign-up',
                component: () => import('@/views/auth/SignUp.vue')
            },
        ]
    },
    {
        path: '/admin/',
        component: AdminLayout,
        children: [
            {
                path: '',
                redirect: '/admin/dashboard'
            },
            {
                path: 'dashboard',
                component: () => import('@/views/admin/Index.vue')
            },
            {
                path: 'account',
                component: () => import('@/views/admin/Account.vue')
            },
            {
                path: 'search',
                component: () => import('@/views/admin/Search.vue')
            },
            {
                path: 'save',
                component: () => import('@/views/admin/Save.vue')
            },
        ]
    },
    // {
    //     path: '/auth/sign-in',
    //     name: 'sign-in',
    //     meta: {
    //         layout: 'public-layout',
    //         public: true,
    //         onlyWhenLoggedOut: true
    //     },
    //     component: () => import ('@/views/auth/SignIn.vue')
    // },
    // {
    //     path: '/auth/sign-up',
    //     name: 'sign-up',
    //     meta: {
    //         layout: 'public-layout',
    //         public: true,
    //         onlyWhenLoggedOut: true
    //     },
    //     component: () => import ('@/views/auth/SignUp.vue')
    // },
    // {
    //     path: '',
    //     name: 'admin-dashboard',
    //     meta: {
    //         layout: 'admin-layout',
    //         public: false,
    //         onlyWhenLoggedOut: false
    //     },
    //     component: () => import ('@/views/admin/Index.vue')
    // }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

router.beforeEach((to, from, next) => {
    const isPublic = to.matched.some(record => record.meta.public);
    const onlyWhenLoggedOut = to.matched.some(
        record => record.meta.onlyWhenLoggedOut
    );
    const loggedIn = !!TokenService.getToken();

    if (!isPublic && !loggedIn) {
        return next({
            path: "/auth/sign-in"
            // query: {redirect: to.fullPath}
        });
    }

    if (loggedIn && onlyWhenLoggedOut) {
        return next("/");
    }

    next();
});

export default router
